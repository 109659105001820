import { createMuiTheme } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', '"Noto Sans JP"', 'sans-serif'].join(','),
  },
  palette: {
    primary: { main: '#fbfb99' },
    secondary: { main: indigo.A400 },
  },
  props: {
    MuiLink: { color: 'secondary' },
    MuiTextField: { variant: 'outlined', color: 'secondary' },
  },
})

export default theme
